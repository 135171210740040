@import '../style/index';
@import '../inputs/variables';

/* Helper Mixin */
@mixin bwc-buttons-group-no-left-border {
  &,
  &:active,
  &:focus {
    border-left-width: 0;
  }
}

/* User-facing Mixin */
@mixin bwc-buttons-group {
  display: flex;

  & > *:first-child {
    border-radius: $bwc-border-radius 0 0 $bwc-border-radius;
  }

  & > *:last-child {
    @include bwc-buttons-group-no-left-border;
    border-radius: 0 $bwc-border-radius $bwc-border-radius 0;
  }

  & > *:not(:first-child):not(:last-child) {
    border-radius: 0;

    @include bwc-buttons-group-no-left-border;
  }
}
