@import '../style/colors';

/// Sizes

$bwc-icons-small: 16px;
$bwc-icons-medium: 2 * $bwc-icons-small;
$bwc-icons-large: 3 * $bwc-icons-small;
$bwc-icons-x-large: 4 * $bwc-icons-small;

/// Colors

$bwc-icons-light: $bwc-gray;
$bwc-icons-dark: $bwc-gray-5;
$bwc-icons-green: $bwc-green;
$bwc-icons-red: $bwc-red;
$bwc-icons-orange: $bwc-orange;
$bwc-icons-blue: $bwc-teal-b;
