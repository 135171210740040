@import '../style/index';
@import '../text/mixins';

@mixin bwc-expandable-blocks-block {
  > .header {
    padding: $bwc-bordered-padding;
    border: 1px solid $bwc-gray-a;

    cursor: pointer;

    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;

    > .icon-container {
      float: left;
      width: 1.5 * $bwc-line-height;
      margin-right: $bwc-unbordered-padding;
      line-height: 3 * $bwc-baseline;
      text-align: center;
      font-size: 18px;
      color: $bwc-gray-5;

      transition: 0.15s ease-in-out;
    }

    > .caption {
      @include no-link;
      @include bwc-text(small);

      display: block;
      color: $bwc-gray-4;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > .title {
      @include no-link;
      @include bwc-text(subheading);

      display: block;
      margin: 0;
      color: $bwc-gray-4;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  > .content {
    padding: 0;
    margin: 0;
    border: 0 none transparent;

    transition: padding-top 0.15s ease-in-out, padding-bottom 0.15s ease-in-out;
  }

  &.with-caption > .header > .icon-container {
    margin-top: $bwc-baseline;
  }

  &.is-collapsed {
    > .content > * {
      display: none;
    }
  }

  &:hover {
    > .header {
      border-color: $bwc-gray-9;

      > .icon-container {
        color: $bwc-gray-2;
      }

      > .caption,
      > .title {
        color: $bwc-gray-1;
      }
    }
  }

  &.is-expanded {
    > .header {
      border-color: $bwc-blue;
      background-color: $bwc-blue;

      > .icon-container,
      > .caption,
      > .title {
        color: $bwc-white;
      }

      > .icon-container {
        transform: rotate(90deg);
      }
    }

    > .content {
      padding: $bwc-bordered-padding;
      border: 1px solid $bwc-blue;
    }

    &:hover {
      > .header,
      > .content {
        border-color: $bwc-blue-5;
      }

      > .header {
        background-color: $bwc-blue-5;

        > .icon-container,
        > .caption,
        > .title {
          color: $bwc-white;
        }
      }
    }
  }
}
