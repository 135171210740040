@import '../style/index';

@mixin bwc-toast($border-color: $bwc-gray-a, $background-color: $bwc-gray-b) {
  flex-direction: row;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  pointer-events: all;
  margin: 0 16px 16px 16px;
  min-height: 42px;
  max-height: 100%;
  background-color: $bwc-white;
  border-radius: $bwc-border-radius;
  border: 1px solid $border-color;
  box-shadow: 0 0 0 1pt rgba(255, 255, 255, 0.5),
    0 7px 40px 0 rgba(0, 0, 0, 0.12);

  @media screen and (max-width: 400px) {
    width: calc(100vw - 36px);
  }

  @media screen and (min-width: 401px) {
    min-width: 400px;
    max-width: 640px;
  }

  .status-icon-container {
    order: 1;
    align-self: stretch;
    min-height: 40px;

    .status-icon-background {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $background-color;
      height: 100%;
      width: 48px;

      .status-icon {
        height: 16px;
        width: 16px;

        path {
          fill: $bwc-white !important;
        }
      }
    }
  }

  .message {
    order: 2;
    flex-grow: 1;
    color: $bwc-text-color;
    font-family: $bwc-font-family;
    font-size: 14px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-right: 24px;
    margin-left: 16px;
    line-height: 22px;
    font-weight: 400;
    font-style: normal;
  }

  .link {
    order: 3;
    color: $bwc-blue;
    font-family: $bwc-font-family;
    font-size: 12px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-right: 24px;
    text-decoration: underline;
    cursor: pointer;
  }

  .close-icon-container {
    order: 4;
    align-self: flex-start;
    margin-top: 12px;
    margin-right: 16px;
    height: 16px;
    width: 16px;
    display: flex;

    .close-icon {
      height: 16px;
      width: 16px;
      cursor: pointer;
      fill: $bwc-gray-5;
    }
  }
}
