@import '../style/index';
@import './button';
@import './disabled';

@mixin bwc-buttons-primary {
  @include bwc-buttons-button;

  &,
  &:active,
  &:focus {
    color: $bwc-white;
    border: none;
    background-color: $bwc-green;
  }

  &:hover {
    background-color: $bwc-green-5;
    color: $bwc-white;
  }
}
