@import './index';

.bwc-buttons-blue {
  @include bwc-buttons-blue;
}

.bwc-buttons-blue-large {
  @include bwc-buttons-blue-large;
}

.bwc-buttons-disabled {
  @include bwc-buttons-disabled;
}

.bwc-buttons-disabled-large {
  @include bwc-buttons-disabled-large;
}

.bwc-buttons-hollow {
  @include bwc-buttons-hollow;
}

.bwc-buttons-hollow-large {
  @include bwc-buttons-hollow-large;
}

.bwc-buttons-primary {
  @include bwc-buttons-primary;
}

.bwc-buttons-primary-large {
  @include bwc-buttons-primary-large;
}

.bwc-buttons-group {
  @include bwc-buttons-group;
}
