@import '../icons/index';
@import '../style/index';
@import './common-mixins';
@import './functions';
@import './variables';

@mixin bwc-tables-checkbox-cell-core($padding: $bwc-tables-padding) {
  $content-size: $bwc-icons-small;
  $label-padding: bwc-tables-padding($padding, $content-size);
  $label-size: bwc-tables-size($padding);

  @include bwc-tables-cell-core;
  @include bwc-tables-bordered-cell;
  @include bwc-tables-bordered-padding($label-padding, '> label');

  position: relative;
  background-clip: padding-box; /// Fix https://stackoverflow.com/a/24492118
  width: $label-size;
  padding: 0;

  > label {
    box-sizing: content-box;
    display: block;
    width: $content-size;
    line-height: $content-size;
    padding: $label-padding;
    margin: 0;
    text-align: left;
    cursor: pointer;

    > input {
      display: inline-block;
      vertical-align: top;
      margin: 2px 0 0 2px;
      cursor: pointer;
    }
  }

  &.partially-selected::after {
    $width: 7px;
    $height: 2px;

    content: ' ';
    display: block;
    position: absolute;
    background-color: $bwc-gray-3;
    left: ($label-size - $width) / 2;
    top: ($label-size - $height) / 2;
    width: $width;
    height: $height;
  }
}
