@import './index';

.bwc-toast-wrapper {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  top: 0;
  right: 0;
  pointer-events: none;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  z-index: 1070;

  @media screen and (max-width: 400px) {
    width: calc(100vw - 36px);
    margin-top: 16px;
  }

  @media screen and (min-width: 401px) {
    min-width: 400px;
    max-width: 640px;
    margin-top: 24px;
  }

  @keyframes openAnimation {
    from {
      opacity: 0;
      transform: translateY(-120%);
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes closeAnimation {
    from {
      transform: scale(1);
      opacity: 1;
    }

    to {
      transform: scale(0.9);
      opacity: 0;
    }
  }

  .bwc-toast-all {
    transition: all 240ms cubic-bezier(0, 0, 0.2, 1);
  }

  .bwc-toast-entering,
  .bwc-toast-entered {
    animation: openAnimation 240ms cubic-bezier(0.175, 0.885, 0.32, 1.175) both;
  }

  .bwc-toast-exiting {
    animation: closeAnimation 120ms cubic-bezier(0.4, 0, 1, 1) both;
  }

  .bwc-toast-success {
    @include bwc-toast-success;
  }

  .bwc-toast-informational {
    @include bwc-toast-informational;
  }

  .bwc-toast-error {
    @include bwc-toast-error;
  }
}
