@import './index';

.bwc-progress-bar {
  @include bwc-progress-bar;
}

.bwc-progress-bar-determinate {
  @include bwc-progress-bar-determinate;
}

.bwc-progress-bar-indeterminate {
  @include bwc-progress-bar-indeterminate;
}

.bwc-progress-bar-labeled {
  @include bwc-progress-bar-labeled;
}

.bwc-progress-spinner-ie {
  @include bwc-progress-spinner-ie;
}

.bwc-progress-spinner {
  @include bwc-progress-spinner;

  &.determinate {
    @include bwc-progress-spinner-determinate;
  }

  &.indeterminate {
    @include bwc-progress-spinner-indeterminate;
  }
}
