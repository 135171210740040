@import '../style/index.scss';

@mixin bwc-progress-bar($height: 4px) {
  text-align: left;
  display: block;
  height: $height;
  width: 100%;
  background: $bwc-gray-9;
  overflow: hidden;

  > * {
    display: inline-block;
    background: $bwc-blue;
    height: 100%;
    vertical-align: top;
  }

  &.success {
    background: $bwc-green;

    > * {
      background: $bwc-green;
    }
  }

  &.error {
    background: $bwc-red;

    > * {
      background: $bwc-red;
    }
  }
}

@mixin bwc-progress-bar-determinate($speed: 2s) {
  transition: width $speed ease-in-out;
}

@mixin bwc-progress-bar-indeterminate() {
  @keyframes bwc-progress-bar-animate-indeterminate {
    0% {
      transform: translateX(-60%) scaleX(0);
    }

    40% {
      transform: translateX(-40%) scaleX(0.4);
    }

    100% {
      transform: translateX(100%) scaleX(0.5);
    }
  }
  width: 100%;
  animation: bwc-progress-bar-animate-indeterminate 1.5s infinite linear;
}

@mixin bwc-progress-bar-labeled() {
  width: 100%;

  > .label {
    text-align: center;
    width: 100%;
    overflow: hidden;
    margin-top: $bwc-xs;
    display: block;

    > :first-child {
      float: left;
    }

    > :last-child {
      float: right;
    }
  }
}
