@import '../style/index';

@mixin bwc-tiles-tile {
  min-height: $bwc-line-height * 14;
  padding: 0 0 $bwc-line-height * 2;
  margin: 0 0 $bwc-line-height;
  text-align: center;
  transition: all 0.2s ease-out;

  background-color: $bwc-gray-b;
  color: $bwc-gray-4;

  > .link {
    display: block;
    padding: $bwc-line-height * 1.5 $bwc-line-height 0;
    margin: 0;
    cursor: pointer;
    color: $bwc-gray-4;

    > .icon {
      width: $bwc-line-height * 3;
      height: $bwc-line-height * 3;
      padding: 9px;
      margin: 0 auto;
      border-radius: $bwc-line-height * 3;
      background-color: $bwc-green;

      transition: all 0.2s ease-out;
    }

    > .title {
      margin: $bwc-line-height * 0.5 auto 0;
      font-size: 21px;
      font-weight: 300;
      line-height: $bwc-line-height * 1.5;
    }
  }

  > .children {
    padding: $bwc-line-height $bwc-line-height 0;
    font-size: 13px;
    line-height: $bwc-line-height;

    > a {
      display: block;
      color: $bwc-gray-3;
    }
  }

  &.minimal {
    min-height: $bwc-line-height * 9;
    padding: 0;

    > .link {
      padding-bottom: $bwc-line-height * 3;
    }
  }

  &.featured,
  &:hover {
    background-color: $bwc-green;
    color: $bwc-white;

    > .link {
      text-decoration: none;
      color: $bwc-white;

      > .icon {
        background-color: transparent !important;
      }
    }

    > .children > a {
      color: $bwc-white;
    }
  }

  &.featured:hover {
    background-color: darken($bwc-green, 5%);
  }

  $step-colors: (
    step-0: $bwc-green,
    step-1: $bwc-teal-1,
    step-2: $bwc-teal-2,
    step-3: $bwc-teal-3,
    step-4: $bwc-teal-4,
    step-5: $bwc-teal-5,
    step-6: $bwc-teal-6,
    step-7: $bwc-teal-7,
    step-8: $bwc-teal-8,
    step-9: $bwc-teal-9,
    step-10: $bwc-teal-a,
    step-11: $bwc-teal-b,
    step-12: $bwc-blue,
    step-13: $bwc-blue-5,
    step-14: $bwc-blue-4,
    step-15: $bwc-blue-3,
  );

  @each $step, $color in $step-colors {
    &.#{$step} > .link > .icon,
    &.#{$step}:hover,
    &.#{$step}.featured {
      background-color: $color;
    }

    &.#{$step}.featured:hover {
      background-color: darken($color, 5%);
    }
  }
}
