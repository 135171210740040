@import '../style/index';

@mixin bwc-tabs {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;

  > li {
    display: block;
    position: relative;
    padding: 0;
    margin: 0;

    > a {
      display: block;
      position: relative;
      padding:
        $bwc-bordered-padding ($bwc-font-size - 1px)
        $bwc-unbordered-padding;
      border: 1px solid $bwc-gray-a;
      border-bottom: 0 none transparent;
      margin: 0;
      line-height: $bwc-line-height;
      color: $bwc-text-color;
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        transition: all 0.5s;
        width: 2px;
        height: 100%;
        top: 0;
        left: 0;
        background: $bwc-blue;
        opacity: 0;
      }

      &:hover {
        text-decoration: none;
        color: $bwc-gray-4;

        &::after {
          opacity: 1;
        }
      }
    }

    &:last-child > a {
      border-bottom: 1px solid $bwc-gray-a;
    }

    &.active {
      > a {
        font-weight: $bwc-font-weight-bold;

        &::after {
          opacity: 1;
        }
      }
    }
  }

  &.green > li > a::after {
    background: $bwc-green;
  }
}
