/// Variables ------------------------------------------------------------------

/// Primary colors:

$bwc-green: #56aa1c;
$bwc-blue: #008be1;
$bwc-dark-navy: #002a44;
$bwc-gray: #9ba5ae;
$bwc-text-color: #2a2f34;

/// Other colors:

$bwc-black: #000;
$bwc-white: #fff;
$bwc-yellow: #ffcf00;
$bwc-orange: #f60;
$bwc-red: #c00;
$bwc-teal: #2b9b7f;

/// Highlights:

$bwc-blue-highlight: #e5f5fd;
$bwc-blue-highlight-alt: #d9eefb;
$bwc-green-highlight: #eef6e8;
$bwc-green-highlight-alt: #ecf5e6;
$bwc-red-highlight: #fae5e5;
$bwc-red-highlight-alt: #f7d9d9;

/// Gray:

$bwc-gray-1: #070809;
$bwc-gray-2: $bwc-text-color;
$bwc-gray-3: #3c434a;
$bwc-gray-4: #4d575f;
$bwc-gray-5: #677480;
$bwc-gray-6: #85929d;
$bwc-gray-7: $bwc-gray;
$bwc-gray-8: #a6afb7;
$bwc-gray-9: #c7ccd1;
$bwc-gray-a: #dce0e3;
$bwc-gray-b: #eef0f3;
$bwc-gray-c: #f8f9fb;

/// Blue:

$bwc-blue-1: #00121d;
$bwc-blue-2: $bwc-dark-navy;
$bwc-blue-3: #00426b;
$bwc-blue-4: #005a92;
$bwc-blue-5: #0073ba;
$bwc-blue-6: $bwc-blue;
$bwc-blue-7: #30b0ff;
$bwc-blue-8: #58bfff;
$bwc-blue-9: #7fceff;
$bwc-blue-a: #a6ddff;
$bwc-blue-b: #cdecff;
$bwc-blue-c: $bwc-blue-highlight;

/// Green:

$bwc-green-1: #010200;
$bwc-green-2: #122306;
$bwc-green-3: #23450b;
$bwc-green-4: #346711;
$bwc-green-5: #458816;
$bwc-green-6: $bwc-green;
$bwc-green-7: #5fbb1f;
$bwc-green-8: #67cc22;
$bwc-green-9: #91e458;
$bwc-green-a: #b2ec8b;
$bwc-green-b: #d4f4bd;
$bwc-green-c: $bwc-green-highlight;

/// Teal:

$bwc-teal-0: $bwc-green;
$bwc-teal-1: #2b9b1b;
$bwc-teal-2: #2b9b2f;
$bwc-teal-3: #2b9b43;
$bwc-teal-4: #2b9b57;
$bwc-teal-5: #2b9b6b;
$bwc-teal-6: $bwc-teal;
$bwc-teal-7: #2b9b93;
$bwc-teal-8: #2b9ba7;
$bwc-teal-9: #2b9bbb;
$bwc-teal-a: #2b9bcf;
$bwc-teal-b: #2b9be3;
$bwc-teal-c: $bwc-blue-6;
$bwc-teal-d: $bwc-blue-5;
$bwc-teal-e: $bwc-blue-4;
$bwc-teal-f: $bwc-blue-3;
