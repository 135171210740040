@import '../style/index';
@import './tabs';

@mixin bwc-tabs-horizontal {
  @include bwc-tabs;

  > li {
    display: inline-block;
    margin: 0 -1px 0 0;
    padding: 0;
    /// float:          left;

    > a {
      line-height: $bwc-line-height;
      padding: 7px 14px;
      /// there is a 1px border
      margin: 6px 0 0;
      background: $bwc-gray-c;

      &::after {
        width: 100%;
        height: 2px;
      }
    }

    &.active {
      > a {
        border-bottom: 1px solid $bwc-white;
        background: $bwc-white;
      }
    }
  }
}
