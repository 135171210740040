@import '../style/index';

@mixin bwc-inputs-checkbox {
  display: block;
  position: relative;
  padding: 0 0 0 $bwc-line-height;
  margin: $bwc-unbordered-padding 0;
  cursor: pointer;

  > input {
    position: absolute;
    left: 4px;
    top: 0;
    height: $bwc-line-height;
    margin: 0;

    font-size: $bwc-font-size;
    line-height: $bwc-line-height;
    text-align: left;

    cursor: pointer;
  }

  > .label {
    display: inline;
    position: relative;
    left: 0;

    padding: 0;
    margin: 0;
    border: 0 none transparent;
    font-size: $bwc-font-size;
    font-style: normal;
    font-weight: $bwc-font-weight-normal;
    line-height: $bwc-line-height;
    text-align: left;
    vertical-align: initial;
    white-space: normal;
    color: $bwc-text-color;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.success > .label {
    color: $bwc-green;
  }

  &.warning > .label {
    color: $bwc-orange;
  }

  &.error > .label {
    color: $bwc-red;
  }

  &.disabled > .label {
    color: $bwc-gray-9;
  }
}
