@import '../style/colors.scss';
@import '../icons/variables.scss';

// Slightly modified version of IE safe spinner here:
// https://codepen.io/JonGraft/pen/oOEeoO?editors=1100.
@mixin bwc-progress-spinner-ie() {
  display: block;
  color: $bwc-blue;
  position: relative;
  box-sizing: border-box;
  padding: 2.5px;
  height: 100%;
  width: 100%;

  &::after {
    position: relative;
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 100%;
    display: inline-block;
    border: 3px solid currentColor;
    border-bottom-color: $bwc-gray-9;
    border-radius: 100%;
    background: transparent;
    animation: dash 1s linear infinite;
  }

  &.small::after {
    border-width: 1.5px;
  }

  @keyframes dash {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

@mixin bwc-progress-spinner() {
  // Main container be positioned to center content
  position: relative;

  svg.spinner {
    height: 100%;
    width: 100%;

    circle.fill {
      stroke: $bwc-blue;
      fill: none;
      stroke-width: 3.2;
    }

    circle.shape {
      stroke: $bwc-gray-9;
      fill: none;
      stroke-width: 3.2;
    }
  }

  // If success, color both bars green so that the fill doesn't matter.
  &.success {
    svg.spinner {
      circle.fill,
      circle.shape {
        stroke: $bwc-green;
      }
    }
  }

  &.error {
    svg.spinner {
      circle.fill,
      circle.shape {
        stroke: $bwc-red;
      }
    }
  }

  // Position this on top of the main spinner container, then center the contents.
  .content {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
  }
}

@mixin bwc-progress-spinner-determinate() {
  svg.spinner {
    // Rotate it 90 degrees so that it starts filling from the top.
    transform: rotate(-90deg);

    // We use a dasharray of 100 so that fill percentage will be base 100.
    circle.fill {
      stroke-dasharray: 100;
      transition: stroke-dashoffset 0.5s ease-in-out;
    }
  }
}

@mixin bwc-progress-spinner-indeterminate() {
  svg.spinner {
    animation: rotate-indeterminate 2s linear infinite;

    circle.fill {
      animation: dash-indeterminate 1.5s ease-in-out infinite;
    }
  }

  @keyframes rotate-indeterminate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash-indeterminate {
    0% {
      stroke-dasharray: 1, 100;
    }

    50% {
      stroke-dasharray: 100, 100;
      stroke-dashoffset: -25;
    }

    100% {
      stroke-dasharray: 100, 100;
      stroke-dashoffset: -100;
    }
  }
}
