@import './variables';

@mixin bwc-icons-small {
  width: $bwc-icons-small;
  height: $bwc-icons-small;
}

@mixin bwc-icons-medium {
  width: $bwc-icons-medium;
  height: $bwc-icons-medium;
}

@mixin bwc-icons-large {
  width: $bwc-icons-large;
  height: $bwc-icons-large;
}

@mixin bwc-icons-x-large {
  width: $bwc-icons-x-large;
  height: $bwc-icons-x-large;
}
