@import './index';

.bwc-text-block {
  @include bwc-text-block;
}

.bwc-text-headline {
  @include bwc-text-headline;
}

.bwc-text-leading {
  @include bwc-text-leading;
}

.bwc-text-leading-2 {
  @include bwc-text-leading-2;
}

.bwc-text-muted {
  @include bwc-text-muted;
}

.bwc-text-small {
  @include bwc-text-small;
}

.bwc-text-subheading {
  @include bwc-text-subheading;
}

.bwc-text-subheading-2 {
  @include bwc-text-subheading-2;
}

.bwc-text-title {
  @include bwc-text-title;
}

.bwc-text-title-2 {
  @include bwc-text-title-2;
}
