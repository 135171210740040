@import './index';

.bwc-inputs-checkbox {
  @include bwc-inputs-checkbox;
}

.bwc-inputs-input {
  @include bwc-inputs-input;
}

.bwc-inputs-labeled-input {
  @include bwc-inputs-labeled-input;
}

.bwc-inputs-labeled-select {
  @include bwc-inputs-labeled-select;
}

.bwc-inputs-labeled-textarea {
  @include bwc-inputs-labeled-textarea;
}

.bwc-inputs-radio {
  @include bwc-inputs-radio;
}

.bwc-inputs-select {
  @include bwc-inputs-select;
}

.bwc-inputs-textarea {
  @include bwc-inputs-textarea;
}
