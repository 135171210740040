/// WARNING: This is Work In Progress and should not be depended on. Subject to change.

@import '../style/index';
@import './alert';

@mixin bwc-alerts-warning {
  $hue: hue($bwc-yellow);
  $saturation: saturation($bwc-yellow);

  $background-color: hsl($hue, $saturation, 95%);
  $border-color: hsl($hue, $saturation, 82%);
  $text-color: hsl($hue, 100%, 30%);

  @include bwc-alerts-alert($background-color, $border-color, $text-color);
}
