/// Imports --------------------------------------------------------------------

@import '../style/index';
@import '../inputs/variables';

/// Mixins ---------------------------------------------------------------------

@mixin bwc-buttons-button {
  display: inline-block;

  padding: $bwc-inputs-padding $bwc-unbordered-padding;
  margin: $bwc-inputs-margin 0;
  border-radius: $bwc-border-radius;
  line-height: $bwc-line-height;
  outline: none;
  box-shadow: none;
  font-family: $bwc-font-family;
  font-size: $bwc-font-size;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
    text-decoration: none;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active,
  &[disabled]:focus {
    @include bwc-buttons-button-disabled;
  }
}

@mixin bwc-buttons-button-disabled {
  opacity: 0.4;
  background-color: $bwc-gray;
  border: 0;
  color: $bwc-white;
  cursor: not-allowed;
}

@mixin bwc-buttons-large-size {
  padding: $bwc-inputs-padding-large $bwc-unbordered-padding-large;
  margin: $bwc-inputs-margin-large 0;
  font-size: $bwc-font-size-leading;
  font-weight: $bwc-font-weight-normal;
}

@mixin bwc-buttons-large-bordered-size {
  padding: $bwc-inputs-padding-bordered-large $bwc-bordered-padding-large;
  margin: $bwc-inputs-margin-large 0;
  font-size: $bwc-font-size-leading;
  font-weight: $bwc-font-weight-normal;
}
