/// WARNING: This is Work In Progress and should not be depended on. Subject to change.

@import './index';

.bwc-alerts-error {
  @include bwc-alerts-error;
}

.bwc-alerts-info {
  @include bwc-alerts-info;
}

.bwc-alerts-success {
  @include bwc-alerts-success;
}

.bwc-alerts-warning {
  @include bwc-alerts-warning;
}
