@import '../icons/index';
@import '../style/index';
@import './common-mixins';
@import './variables';

@mixin bwc-tables-cell(
  $padding: $bwc-tables-padding,
  $line-height: $bwc-line-height
) {
  @include bwc-tables-padded-cell($padding, $line-height);
  @include bwc-tables-body-colors;

  > .icon {
    font-size: $bwc-icons-small;
  }
}
