@import '../style/index';
@import '../inputs/variables';
@import './button';
@import './disabled';

@mixin bwc-buttons-hollow {
  @include bwc-buttons-button;

  &,
  &:active,
  &:focus {
    padding: $bwc-inputs-padding-bordered $bwc-bordered-padding;
    border: 1px solid $bwc-gray-6;
    background-color: transparent;
    color: $bwc-gray-3;
  }

  &:hover {
    background-color: transparent;
    border-color: $bwc-gray-4;
    color: $bwc-gray-1;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active,
  &[disabled]:focus {
    padding: $bwc-inputs-padding $bwc-unbordered-padding;
  }
}
