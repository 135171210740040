@import '../style/index';

@mixin bwc-inputs-labeled-textarea {
  display: block;
  padding: 0;
  margin: 0 0 $bwc-unbordered-padding;
  line-height: 0; /// A trick to remove extra padding-bottom in Chrome and FF
  text-align: left;
  cursor: pointer;
  font-weight: normal;

  > .label {
    padding: 0;
    margin: 0;
    border: 0 none transparent;
    display: block;
    font-size: $bwc-font-size;
    font-weight: $bwc-font-weight-semibold;
    line-height: $bwc-line-height;
    text-align: left;
    color: $bwc-text-color;
  }

  > .bwc-inputs-textarea {
    display: inline-block;
    width: 100%;
  }

  > .message {
    display: block;
    font-size: $bwc-font-size-small;
    line-height: $bwc-line-height;
    color: $bwc-gray;
  }

  /// Status colors ----------------------------------------------------------

  $status-colors: (
    success: $bwc-green,
    warning: $bwc-orange,
    error: $bwc-red,
  );

  @each $status, $color in $status-colors {
    &.#{$status},
    &.#{$status} > .message {
      color: $color;
    }

    &.#{$status} {
      > textarea,
      > .bwc-inputs-textarea {
        border-color: $color;
        box-shadow: rgba($bwc-black, 0.075) 0 1px 1px inset;

        &:focus {
          border-color: $color;
          box-shadow:
            rgba($bwc-black, 0) 0 1px 1px inset,
            rgba($color, 0.15) 0 0 0 2px;
        }
      }
    }
  }
}
