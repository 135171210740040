@import '../icons/index';
@import '../style/index';
@import './functions';

@mixin bwc-tables-cell-core {
  display: table-cell;
  box-sizing: content-box;

  text-align: left;
  vertical-align: top;

  font-size: $bwc-font-size;
  font-weight: $bwc-font-weight-normal;
}

@mixin bwc-tables-bordered-cell {
  border: 0 solid $bwc-red;
  border-bottom-width: 1px;

  &:first-child {
    border-left-width: 1px;
  }

  &:last-child {
    border-right-width: 1px;
  }
}

@mixin bwc-tables-bordered-padding($padding, $child-selector: '') {
  &:first-child #{$child-selector} {
    padding-left: $padding - 1px;
  }

  &:last-child #{$child-selector} {
    padding-right: $padding - 1px;
  }
}

@mixin bwc-tables-padded-cell(
  $padding: $bwc-tables-padding,
  $content-height: $bwc-line-height,
  $content-width: $bwc-tables-min-width
) {
  $v-padding: bwc-tables-padding($padding, $content-height);
  $h-padding: bwc-tables-padding($padding, $content-width);

  @include bwc-tables-cell-core;
  @include bwc-tables-bordered-cell;
  @include bwc-tables-bordered-padding($h-padding);
  min-width: $content-width;
  line-height: $content-height;
  padding: $v-padding $h-padding;
}

@mixin bwc-tables-head-colors {
  background-color: $bwc-tables-head-light;
  color: $bwc-black;
  border-bottom-color: $bwc-tables-head-dark;
  border-left-color: $bwc-tables-head-light;
  border-right-color: $bwc-tables-head-light;

  &:hover {
    background-color: $bwc-tables-head-dark;
    border-left-color: $bwc-tables-head-dark;

    border-right-color: $bwc-tables-head-dark;
  }
}

@mixin bwc-tables-body-colors {
  background-color: $bwc-white;
  color: $bwc-text-color;
  border-bottom-color: $bwc-tables-border-color;
  border-left-color: $bwc-white;
  border-right-color: $bwc-white;
}
