@import '../style/index';

@mixin bwc-inputs-labeled-input {
  display: block;
  padding: 0;
  margin: 0 0 $bwc-unbordered-padding;
  text-align: left;
  font-size: $bwc-font-size;
  font-weight: $bwc-font-weight-normal;
  cursor: pointer;

  > .label {
    padding: 0;
    margin: 0;
    border: 0 none transparent;
    display: block;
    font-size: $bwc-font-size;
    font-weight: $bwc-font-weight-semibold;
    line-height: $bwc-line-height;
    text-align: left;
    color: $bwc-text-color;
  }

  > .input {
    position: relative;
    /// Needed to vertically align the icon:
    display: inline-block;
    width: 100%;

    &.with-icon {
      > input,
      > .bwx-inputs-input {
        padding-right: $bwc-bordered-padding + $bwc-line-height;
      }
    }

    > .icon {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      padding: $bwc-unbordered-padding;

      font-size: 16px;
      font-weight: normal;
      line-height: $bwc-line-height;
    }
  }

  > .message {
    display: block;
    font-size: $bwc-font-size-small;
    line-height: $bwc-line-height;
    color: $bwc-gray;
  }

  /// Status colors ----------------------------------------------------------

  $status-colors: (
    success: $bwc-green,
    warning: $bwc-orange,
    error: $bwc-red,
  );

  @each $status, $color in $status-colors {
    &.#{$status},
    &.#{$status} > .message {
      color: $color;
    }

    &.#{$status} > .input {
      > input,
      > .bwc-inputs-input {
        border-color: $color;
        box-shadow: rgba($bwc-black, 0.075) 0 1px 1px inset;

        &:focus {
          border-color: $color;
          box-shadow:
            rgba($bwc-black, 0) 0 1px 1px inset,
            rgba($color, 0.15) 0 0 0 2px;
        }
      }
    }
  }
}
