@import '../style/index';
@import './variables';

@mixin bwc-inputs-select {
  display: inline-block;
  height: $bwc-inputs-height;
  padding: $bwc-inputs-padding ($bwc-bordered-padding - 3px);
  margin: $bwc-inputs-margin 0;
  font-size: $bwc-font-size;
  line-height: $bwc-line-height;
  color: $bwc-text-color;
  background-color: $bwc-white;
  background-image: none;
  border: 1px solid $bwc-inputs-border-color;
  border-radius: $bwc-border-radius;
  box-shadow: rgba($bwc-black, 0.075) 0 1px 1px inset;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:invalid {
    color: $bwc-gray-9;

    > option {
      color: $bwc-text-color;
    }
  }

  > option.placeholder {
    display: none;
  }

  &[disabled],
  &[readonly] {
    background-color: $bwc-gray-b;
    color: $bwc-gray-9;
    cursor: not-allowed;
  }

  &:focus {
    border-color: $bwc-inputs-border-color;
    outline: 0;
    box-shadow:
      rgba($bwc-black, 0) 0 1px 1px inset,
      rgba($bwc-blue, 0.1) 0 0 0 2px;
  }
}
