@import './index';

.bwc-icons-large {
  @include bwc-icons-large;
}

.bwc-icons-medium {
  @include bwc-icons-medium;
}

.bwc-icons-small {
  @include bwc-icons-small;
}

.bwc-icons-x-large {
  @include bwc-icons-x-large;
}
