@import '../icons/index';
@import './common-mixins';
@import './functions';
@import './variables';

@mixin bwc-tables-head-label-cell($padding: $bwc-tables-padding) {
  @include bwc-tables-cell-core;
  @include bwc-tables-bordered-cell;
  @include bwc-tables-head-colors;

  $icon-padding: bwc-tables-padding($padding, $bwc-icons-small);
  $size: bwc-tables-size($padding);

  padding: 0;

  > .label-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-start;

    padding: 0;
    margin: 0;

    > .label-text {
      flex: 0 1 auto;

      min-width: 3em;
      line-height: $size;
      padding-left: $padding;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > .filter-icon {
      @include bwc-icons-small;

      flex: 0 0 auto;
      box-sizing: content-box;
      padding: $icon-padding;
    }

    > .label-spacer {
      flex: 1 1 0;

      width: 0;
      padding: 0;
      margin: 0;
    }

    > .sort-icon {
      @include bwc-icons-small;

      flex: 0 0 auto;

      box-sizing: content-box;
      padding: $icon-padding;
    }

    > .label-text:last-child,
    > .label-spacer:last-child {
      padding-right: $padding;
    }
  }

  &.is-sortable > .label-container > .sort-icon {
    opacity: 0;
  }

  &.is-sorted > .label-container > .sort-icon {
    opacity: 1;
  }

  /// Bordered padding: ------------------------------------------------------

  &:first-child > .label-container {
    > .label-text:first-child,
    > .label-spacer:first-child {
      padding-left: $padding - 1px;
    }

    > .filter-icon:first-child,
    > .sort-icon:first-child {
      padding-left: $icon-padding - 1px;
    }
  }

  &:last-child > .label-container {
    > .label-text:last-child,
    > .label-spacer:last-child {
      padding-right: $padding - 1px;
    }

    > .filter-icon:last-child,
    > .sort-icon:last-child {
      padding-right: $icon-padding - 1px;
    }
  }

  /// Sortable columns -------------------------------------------------------

  &.is-sortable,
  &.is-sorted {
    cursor: pointer;
  }

  &.is-sortable:hover > .label-container > .sort-icon {
    /// Use an *actionable* .sort-icon and this will give the approximate $bwc-gray value:
    opacity: 0.65;
  }
}
