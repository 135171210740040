@import '../style/index';

@mixin bwc-text-block-spacing {
  padding: 0;
  margin: 0 0 $bwc-line-height;
}

@mixin bwc-text($type: body) {
  $sizes: (
    headline: $bwc-font-size-headline,
    title: $bwc-font-size-title,
    title-2: $bwc-font-size-title,
    subheading: $bwc-font-size-subheading,
    subheading-2: $bwc-font-size-subheading,
    leading: $bwc-font-size-leading,
    leading-2: $bwc-font-size-leading,
    body: $bwc-font-size,
    small: $bwc-font-size-small,
    caption: $bwc-font-size-small,
  );

  $weights: (
    headline: $bwc-font-weight-light,
    title: $bwc-font-weight-light,
    title-2: $bwc-font-weight-normal,
    subheading: $bwc-font-weight-normal,
    subheading-2: $bwc-font-weight-semibold,
    leading: $bwc-font-weight-normal,
    leading-2: $bwc-font-weight-bold,
    body: $bwc-font-weight-normal,
    small: $bwc-font-weight-normal,
    caption: $bwc-font-weight-normal,
  );
  $line-heights: (
    headline: 2 * $bwc-line-height,
    title: 1.5 * $bwc-line-height,
    title-2: 1.5 * $bwc-line-height,
    subheading: 1.5 * $bwc-line-height,
    subheading-2: 1.5 * $bwc-line-height,
    leading: $bwc-line-height,
    leading-2: $bwc-line-height,
    body: $bwc-line-height,
    small: $bwc-line-height,
    caption: $bwc-line-height,
  );
  $type: if(map-has-key($sizes, $type), $type, body);
  font-size: map-get($sizes, $type);
  font-weight: map-get($weights, $type);
  line-height: map-get($line-heights, $type);
}
