/// WARNING: This is Work In Progress and should not be depended on. Subject to change.

@import '../style/index';
@import './alert';

@mixin bwc-alerts-info {
  @include bwc-alerts-alert(
    $background-color: $bwc-blue-highlight,
    $border-color: darken($bwc-blue-highlight, 5%),
    $text-color: $bwc-blue
  );
}
