@import './baseline';

/// Constants ------------------------------------------------------------------

$bwc-sans: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$bwc-monospace: Consolas, 'Liberation Mono', Menlo, Courier, monospace;

$bwc-font-family: $bwc-sans;

$bwc-font-size: 14px;
$bwc-font-size-small: 12px;
$bwc-font-size-leading: 16px;
$bwc-font-size-subheading: 18px;
$bwc-font-size-title: 24px;
$bwc-font-size-headline: 32px;

$bwc-cap-size: 10px;
$bwc-cap-size-small: 9px;
$bwc-cap-size-leading: 12px;
$bwc-cap-size-subheading: 13px;
$bwc-cap-size-title: 17px;
$bwc-cap-size-headline: 23px;

$bwc-font-weight-light: 300;
$bwc-font-weight-normal: 400;
$bwc-font-weight-semibold: 600;
$bwc-font-weight-bold: 700;

/// Font loading helpers -------------------------------------------------------
/// See: https://www.zachleat.com/web/comprehensive-webfonts

$bwc-font-loaded-class: null !default;

@mixin bwc-font-family {
  @if $bwc-font-loaded-class {
    font-family: sans-serif;

    &.#{ $bwc-font-loaded-class } {
      font-family: $bwc-font-family;
    }

    @at-root {
      body.#{ $bwc-font-loaded-class } & {
        font-family: $bwc-font-family;
      }
    }
  }

  @else {
    font-family: $bwc-font-family;
  }
}
