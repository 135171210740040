@import './index';

/// Default table --------------------------------------------------------------

.bwc-tables-table {
  @include bwc-tables-table;
}

.bwc-tables-cell {
  @include bwc-tables-cell;
}

.bwc-tables-checkbox-cell {
  @include bwc-tables-checkbox-cell;
}

.bwc-tables-checkbox-cell-core {
  @include bwc-tables-checkbox-cell-core;
}

.bwc-tables-empty-cell {
  @include bwc-tables-empty-cell;
}

.bwc-tables-head-checkbox-cell {
  @include bwc-tables-head-checkbox-cell;
}

.bwc-tables-head-empty-cell {
  @include bwc-tables-head-empty-cell;
}

.bwc-tables-head-icon-cell {
  @include bwc-tables-head-icon-cell;
}

.bwc-tables-head-label-cell {
  @include bwc-tables-head-label-cell;
}

.bwc-tables-icon-cell {
  @include bwc-tables-icon-cell;
}

.bwc-tables-row {
  @include bwc-tables-row;
}

/// Condensed table ------------------------------------------------------------

.bwc-tables-table-condensed {
  @include bwc-tables-table;

  $padding: $bwc-tables-padding-condensed;

  width: auto;

  .bwc-tables-cell {
    @include bwc-tables-cell($padding);
  }

  .bwc-tables-checkbox-cell {
    @include bwc-tables-checkbox-cell($padding);
  }

  .bwc-tables-checkbox-cell-core {
    @include bwc-tables-checkbox-cell-core($padding);
  }

  .bwc-tables-empty-cell {
    @include bwc-tables-empty-cell($padding);
  }

  .bwc-tables-head-checkbox-cell {
    @include bwc-tables-head-checkbox-cell($padding);
  }

  .bwc-tables-head-empty-cell {
    @include bwc-tables-head-empty-cell($padding);
  }

  .bwc-tables-head-icon-cell {
    @include bwc-tables-head-icon-cell($padding);
  }

  .bwc-tables-head-label-cell {
    @include bwc-tables-head-label-cell($padding);
  }

  .bwc-tables-icon-cell {
    @include bwc-tables-icon-cell($padding);
  }

  .bwc-tables-row {
    @include bwc-tables-row;
  }
}

/// Extra-condensed Table ------------------------------------------------------

.bwc-tables-table-condensed.extra-condensed {
  @include bwc-tables-table;

  $padding: $bwc-tables-padding-x-condensed;
  $line-height: 18px;

  width: auto;

  .bwc-tables-cell {
    @include bwc-tables-cell($padding, $line-height);
  }

  .bwc-tables-checkbox-cell {
    @include bwc-tables-checkbox-cell($padding);
  }

  .bwc-tables-checkbox-cell-core {
    @include bwc-tables-checkbox-cell-core($padding);
  }

  .bwc-tables-empty-cell {
    @include bwc-tables-empty-cell($padding);
  }

  .bwc-tables-head-checkbox-cell {
    @include bwc-tables-head-checkbox-cell($padding);
  }

  .bwc-tables-head-empty-cell {
    @include bwc-tables-head-empty-cell($padding);
  }

  .bwc-tables-head-icon-cell {
    @include bwc-tables-head-icon-cell($padding);
  }

  .bwc-tables-head-label-cell {
    @include bwc-tables-head-label-cell($padding);
  }

  .bwc-tables-icon-cell {
    @include bwc-tables-icon-cell($padding);
  }

  .bwc-tables-row {
    @include bwc-tables-row;
  }
}
