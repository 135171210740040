$bwc-dataviz-blue-1: #4585a5;
$bwc-dataviz-blue-2: #85a9cf;
$bwc-dataviz-blue-3: #6ab9ec;
$bwc-dataviz-brown-1: #a47854;
$bwc-dataviz-brown-2: #c8c2b4;
$bwc-dataviz-green: #b1c854;
$bwc-dataviz-orange: #f7963e;
$bwc-dataviz-purple: #a3779f;
$bwc-dataviz-red: #f7706c;
$bwc-dataviz-teal: #73c7c1;
$bwc-dataviz-yellow: #ffc335;
