@import '../style/index';

@mixin bwc-loaders-step($step: 0) {
  $child-num: $step + 1;

  $before-factor: $step / 12;
  $after-factor: ($step + 6) / 12;
  $after-step: $step + 6;

  > *:nth-child(#{ $child-num })::before {
    transform: rotate(-360deg * $before-factor);
    animation-delay: -1s * $before-factor;
  }

  > *:nth-child(#{ $child-num })::after {
    transform: rotate(-360deg * $after-factor);
    animation-delay: -1s * $after-factor;
  }
}

@mixin bwc-loaders-loader($size: 32px) {
  @keyframes bwc-loaders-animate-opacity {
    0% {
      opacity: 0.85;
    }

    50% {
      opacity: 0.25;
    }

    100% {
      opacity: 0.25;
    }
  }

  $part-width: round($size / 16) + 1px;
  $part-height: $size / 4;

  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: $size;
  height: $size;
  margin: 0;
  text-indent: 999em;
  overflow: hidden;
  background: transparent;

  > *::before,
  > *::after {
    content: '';
    position: absolute;
    top: 0;
    left: ($size - $part-width) / 2;
    width: $part-width;
    height: $part-height;
    border-radius: $bwc-border-radius;
    background: $bwc-text-color;
    transform-origin: ($part-width / 2) ($size / 2);

    animation: bwc-loaders-animate-opacity 1s linear infinite;
  }

  &.white {
    > *::before,
    > *::after {
      background: $bwc-white;
    }
  }

  @include bwc-loaders-step($step: 0);
  @include bwc-loaders-step($step: 1);
  @include bwc-loaders-step($step: 2);
  @include bwc-loaders-step($step: 3);
  @include bwc-loaders-step($step: 4);
  @include bwc-loaders-step($step: 5);
}
