@import '../style/index';
@import './button';

@mixin bwc-buttons-disabled {
  @include bwc-buttons-button;

  &,
  &:active,
  &:focus,
  &:hover {
    @include bwc-buttons-button-disabled;
  }
}
