@import './common-mixins';
@import './variables';

@mixin bwc-tables-head-empty-cell($padding: $bwc-tables-padding) {
  @include bwc-tables-padded-cell($padding);

  width: $bwc-tables-min-width;

  background-color: $bwc-tables-head-light;
  color: $bwc-black;
  border-bottom-color: $bwc-tables-head-dark;
  border-left-color: $bwc-tables-head-light;
  border-right-color: $bwc-tables-head-light;

  &:hover {
    background-color: $bwc-tables-head-light;
    border-left-color: $bwc-tables-head-light;
    border-right-color: $bwc-tables-head-light;
  }
}
