@import '../icons/index';
@import './common-mixins';
@import './variables';

@mixin bwc-tables-icon-cell-core($padding: $bwc-tables-padding) {
  @include bwc-tables-padded-cell($padding, $bwc-icons-small, $bwc-icons-small);

  width: $bwc-icons-small;

  font-size: $bwc-icons-small;
  color: $bwc-icons-dark;
  fill: $bwc-icons-dark;

  cursor: pointer;

  > img,
  > svg {
    @include bwc-icons-small;

    padding: 0;
    margin: 0;
    vertical-align: top;
  }
}
