@import './common-mixins';
@import './icon-cell-core';
@import './variables';

@mixin bwc-tables-icon-cell($padding: $bwc-tables-padding) {
  @include bwc-tables-icon-cell-core($padding);
  @include bwc-tables-body-colors;

  color: $bwc-icons-dark;
  fill: $bwc-icons-dark;
}
