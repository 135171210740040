@import './variables';

@mixin bwc-tables-row {
  > td {
    > .expand-icon {
      display: inline-block;
      transition: 0.15s ease-in-out;
    }
  }

  &:hover > td {
    background-color: $bwc-tables-hover;
    border-left-color: $bwc-tables-hover;
    border-right-color: $bwc-tables-hover;
  }

  &.expanded-child {
    > td {
      border-left-color: $bwc-tables-expanded-color;
      border-right-color: $bwc-tables-expanded-color;
    }
  }

  &.expanded-parent {
    > td {
      border-color: $bwc-tables-expanded-color;
      background-color: $bwc-tables-expanded-color;
      color: white;

      > .expand-icon {
        transform: rotate(90deg);
      }
    }

    &:hover > td {
      border-color: $bwc-tables-expanded-hover;
      background-color: $bwc-tables-expanded-hover;
    }
  }

  &.expanded-child.last-child > td {
    border-bottom-color: $bwc-tables-expanded-color;
  }
}
