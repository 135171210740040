/// WARNING: This is Work In Progress and should not be depended on. Subject to change.

@import '../style/index';
@import '../text/index';

@mixin bwc-alerts-alert(
  $background-color: $bwc-gray-b,
  $border-color: $bwc-gray-a,
  $text-color: $bwc-text-color
) {
  @include bwc-text-block;
  padding: $bwc-bordered-padding;
  border: 1px solid $border-color;
  border-radius: $bwc-border-radius;
  background-color: $background-color;
  color: $text-color;

  > .icon:first-child {
    display: inline-block;
    position: relative;
    top: 2px;
    margin-right: $bwc-bordered-padding;
    font-size: 16px;
  }

  > a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
