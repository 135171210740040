@import './colors';

@mixin no-border {
  border: 0 none transparent;
}

@mixin no-link {
  text-decoration: none;
  color: $bwc-text-color;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: $bwc-text-color;
    text-decoration: none;
  }
}
