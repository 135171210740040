/// WARNING: This is Work In Progress and should not be depended on. Subject to change.

@import '../style/index';
@import './alert';

@mixin bwc-alerts-success {
  @include bwc-alerts-alert(
    $background-color: $bwc-green-highlight,
    $border-color: darken($bwc-green-highlight, 5%),
    $text-color: $bwc-green
  );
}
