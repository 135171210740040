@import '../style/index';
@import './variables';

@mixin bwc-inputs-input {
  display: block;
  width: 100%;
  padding: $bwc-inputs-padding-bordered $bwc-bordered-padding;
  margin: $bwc-inputs-margin 0;
  font-family: $bwc-font-family;
  font-size: $bwc-font-size;
  line-height: $bwc-line-height;
  color: $bwc-text-color;
  background-color: $bwc-white;
  background-image: none;
  border: 1px solid $bwc-inputs-border-color;
  border-radius: $bwc-border-radius;
  box-shadow: rgba($bwc-black, 0.075) 0 1px 1px inset;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;

  &::-webkit-input-placeholder {
    color: $bwc-gray-9;
  }

  &:-ms-input-placeholder {
    color: $bwc-gray-9;
  }

  &::placeholder {
    color: $bwc-gray-9;
  }

  &[disabled],
  &[readonly] {
    background-color: $bwc-gray-b;
    cursor: not-allowed;
  }

  &:focus {
    border-color: $bwc-inputs-border-color;
    outline: 0;
    box-shadow:
      rgba($bwc-black, 0) 0 1px 1px inset,
      rgba($bwc-blue, 0.1) 0 0 0 2px;
  }
}
