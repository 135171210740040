@import '../style/colors.scss';

@mixin bwc-toggle-switch {
  display: flex;
  height: 30px;
  width: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .base {
    display: block;
    position: relative;
    height: 18px;
    width: 35px;
    border-radius: 10px;
    background-color: $bwc-gray-9;
  }

  .toggle {
    position: absolute;
    height: 12px;
    width: 12px;
    top: 3px;
    right: 21px;
    border-radius: 50%;
    background-color: $bwc-gray-3;
    transition: right 0.2s;
  }

  &.on {
    .base {
      background-color: $bwc-blue;
    }

    .toggle {
      background-color: white;
      right: 4px;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}
