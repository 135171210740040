@import '../style/index';

@mixin bwc-expandable-blocks-list {
  > div {
    transition: margin 0.15s ease-in-out;

    > .header {
      border-color: $bwc-gray-a transparent transparent;
    }

    &:first-child {
      > .header {
        border-top-color: transparent;
      }
    }
  }

  > .is-expanded + div {
    > .header {
      border-top-color: transparent;
    }

    &.is-expanded > .header {
      border-top-color: $bwc-blue;
    }
  }

  > .is-expanded + .is-expanded {
    margin-top: $bwc-unbordered-padding;
  }
}
