@import './button';
@import './hollow';

@mixin bwc-buttons-hollow-large {
  @include bwc-buttons-hollow;
  @include bwc-buttons-large-bordered-size;

  &,
  &:active,
  &:focus {
    @include bwc-buttons-large-bordered-size;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:active,
  &[disabled]:focus {
    @include bwc-buttons-large-size;
  }
}
