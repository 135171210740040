@import '../icons/index';
@import '../style/index';

$bwc-tables-expanded-color: $bwc-blue;
$bwc-tables-expanded-hover: $bwc-blue-5;
$bwc-tables-hover: $bwc-blue-highlight;
$bwc-tables-padding: $bwc-m;
$bwc-tables-padding-condensed: $bwc-sm;
$bwc-tables-padding-x-condensed: $bwc-s;

$bwc-tables-border-color: $bwc-gray-a;
$bwc-tables-head-dark: $bwc-gray-a;
$bwc-tables-head-light: $bwc-gray-b;
$bwc-tables-min-width: $bwc-cap-size;
