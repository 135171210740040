@import '../style/colors.scss';
@import '../style/space.scss';
@import '../text/mixins.scss';

@mixin bwc-tag {
  height: 34px;
  border: 1px solid $bwc-gray-9;
  border-radius: 20px;
  padding-left: $bwc-m;
  padding-right: $bwc-m;
  color: $bwc-gray-2;
  display: inline-flex;
  align-items: center;

  &:hover {
    border-color: $bwc-gray-5;
    color: black;
  }
}

@mixin bwc-tag-text {
  @include bwc-text;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin bwc-tag-icon {
  margin-left: $bwc-s;
  width: $bwc-sm;
  height: $bwc-sm;
  min-width: $bwc-sm;
  color: $bwc-gray-2;
  fill: $bwc-gray-2;
  cursor: pointer;
}
