@import './index';

.bwc-loaders-large {
  @include bwc-loaders-large;
}

.bwc-loaders-medium {
  @include bwc-loaders-medium;
}

.bwc-loaders-small {
  @include bwc-loaders-small;
}
