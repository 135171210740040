@import '../style/colors.scss';
@import '../style/space.scss';
@import '../text/index.scss';

@mixin bwc-non-ideal-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@mixin bwc-non-ideal-state-icon {
  max-width: 440px;
  max-height: 242px;
  margin-bottom: 22px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  svg,
  img {
    width: 100%;
    height: 100%;
  }
}

@mixin bwc-non-ideal-state-title {
  @include bwc-text-headline;
  display: inline-block;
}

@mixin bwc-non-ideal-state-description {
  @include bwc-text-block;
  display: inline-block;
  font-size: 14px;
}

@mixin bwc-non-ideal-state-actions {
  display: flex;

  button:not(:last-child):not(:only-child),
  a:not(:last-child):not(:only-child) {
    margin-right: $bwc-s;
  }
}
