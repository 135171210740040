@import '../style/index';

$lightGray: $bwc-gray-b;
$darkGray: $bwc-gray-a;

$paginatorBg: #f9f9f9;
$paginatorText: #2a2f34;
$paginatorText2: #9ba5ae;
$arrow: #333;

$blueHighlight: $bwc-blue-highlight;

$cellPadding: 13px 13px 13px 16px;

$scrollbarWidth: 17px;

@mixin bwc-tables-table($cols) {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;

  * {
    box-sizing: border-box;
  }

  > .bwc-tables-body {
    width: 100%;
  }

  &.fixed > .bwc-tables-body {
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
  }

  .bwc-tables-row {
    display: flex;
    flex-grow: 1;
    width: 100%;

    &:hover {
      background-color: $blueHighlight;
    }
    /* stylelint-disable-next-line */
    > .bwc-tables-cell {
      align-items: center;
      display: flex;
      flex-grow: 1;
      width: calc(100% / #{$cols});
      min-width: 145px;
      padding: $cellPadding;
      overflow: hidden;
      list-style: none;
      border-bottom: 1px solid $darkGray;

      &.slot {
        width: 50px;
        flex-grow: 0;
        min-width: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        > * {
          cursor: pointer;
        }

        > .icon {
          width: 24px;
          height: 24px;
        }
      }

      &.head {
        background-color: $lightGray;
        height: 50px;

        > .filter {
          margin-left: 8px;
          cursor: pointer;

          > .icon {
            width: 16px;
            height: 16px;
          }
        }

        > .sort {
          flex-grow: 1;
          justify-content: flex-end !important;

          > .icon-wrapper {
            cursor: pointer;

            > .icon {
              width: 16px;
              height: 16px;
            }
          }
        }

        > .filter,
        > .sort,
        > .sort > .icon-wrapper {
          height: 50px;
          width: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:hover {
          background-color: $darkGray;
        }
      }

      &.spacer {
        width: 17px;
        flex-grow: 0;
        min-width: 0;
        padding: 0;

        &.head:hover {
          background-color: $lightGray;
        }

        &:hover {
          background-color: #fff;
        }
      }
    }
  }

  .bwc-tables-paginator {
    width: 100%;
    font-size: 0.9rem;
    background-color: $paginatorBg;

    > .content {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 38px;
      border-bottom: 1px solid $darkGray;

      > div {
        flex: 1;
      }

      > .controls {
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;

        > .current {
          color: #008be1 !important;
          text-decoration: underline #008be1;
          margin: 6px;
          cursor: pointer;
          font-weight: 700;
        }

        > .page,
        > .current {
          margin: 0 6px;
          color: $paginatorText;
        }

        /* stylelint-disable-next-line */
        > .icon {
          width: 16px;
          height: 16px;
          color: $arrow;
          fill: $arrow;
        }
      }

      > .location {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px;
        color: $paginatorText2;
      }
    }
  }
}
